import { TriggerKeys } from "@models/incidentConfig";
import { getConfig } from "../di/di";

export const isDev = process.env.NODE_ENV !== "production";

//TODO it was object before and should be object
export const getLabels = (): Record<string, string> => {
  const objectsAndEventsLabels =
    getConfig().objectsAndEventsLabels[DEFAULT_LANG_CODE] || {};
  return {
    first_pass_in: "First passenger in",
    last_pass_in: "Last passenger in",
    first_pass_out: "First passenger out",
    last_pass_out: "Last passenger out",

    pax_door_together: "Passenger door",
    pax_door_open: "open",
    pax_door_closed: "closed",
    pax: "Passengers",
    ...objectsAndEventsLabels,
  };
};

export const notificationTriggerLabels: Record<TriggerKeys, string> = {
  happened: "happened",
  not_happened: "did not happen",
};

export const NEW_PTS = "new_pts";

export const DEFAULT_LANG_CODE = "en";

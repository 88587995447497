import { ModalDropdownSelectOption } from "@frontend/assaia-ui";
import { PtsEventScheduleOptions } from "@models/pts";
import { getConfig } from "../di/di";
import { parseTurnParam } from "./data";
import { DEFAULT_LANG_CODE } from "./constants";

export const getReferencePointsOptions = (
  target: string[]
): ModalDropdownSelectOption[] =>
  target.map((value) => ({
    id: value,
    title: parseTurnParam(value),
  }));

export const getReferencePointTitle = (
  key: PtsEventScheduleOptions["referencePoint"]
): string => {
  const { ptsTurnaroundParams } = getConfig();
  const referencePoints = getReferencePointsOptions(ptsTurnaroundParams);
  return referencePoints.find((v) => v.id === key)?.title || "SOBT";
};

export const getShortReferencePointTitle = (
  key: PtsEventScheduleOptions["referencePoint"]
): string => {
  if (key === "aircraft_start_ts") {
    return "T-START";
  } else if (key === "aircraft_end_ts") {
    return "T-END";
  }

  return getReferencePointTitle(key);
};

export const getPtsLabel = (v: string) => {
  const { ptsLabels } = getConfig();
  const localizedPtsLabels = ptsLabels[DEFAULT_LANG_CODE] || {};
  return localizedPtsLabels[v] || v;
};

import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "./style.module.scss";
import { observer } from "mobx-react";
import { HomeContext } from "@services/react";

import { useHistory, useParams } from "react-router-dom";
import GroupHeader from "../Common/GroupHeader";
import { PtsSchedule } from "@models/pts";
import Spinner from "../Common/Spinner";
import classNames from "classnames";
import PtsOperationModal from "../PtsOperationModal";
import { getStatusValue, parseTurnaroundLengths } from "@services/data";
import { exportPtsScheduleToCSV } from "@services/export";
import ExportButton from "../Common/ExportButton";
import Content from "../Common/Content";
import { TableColumn, Table } from "@frontend/assaia-ui";
import { getReferencePointTitle } from "@services/ptsUtils";
import { PtsScheduleStore } from "@stores/PtsScheduleStore";
import { getConfig } from "../../di/di";
import { DEFAULT_LANG_CODE } from "@services/constants";

const getIntervalColumn = (key: "start" | "end") => {
  return {
    title: `${key} window`,
    render: (v: PtsSchedule) => {
      const { referencePoint, idealTime, orangeInterval, redInterval } = v[key];
      const parseInterval = (v: number | null) =>
        v !== null ? `${v / 60}m` : "-";

      const items = [
        redInterval.start,
        orangeInterval.start,
        idealTime,
        orangeInterval.end,
        redInterval.end,
      ].map(parseInterval);

      return (
        <div className={styles.columnContent}>
          <span className={styles.title}>{`${getReferencePointTitle(
            referencePoint
          )}: ${idealTime / 60}m`}</span>
          <span className={styles.subtitle}>
            {items.map((v, i) => (
              <span key={i}>{v}</span>
            ))}
          </span>
        </div>
      );
    },
  };
};

const getColumns = () => {
  const { ptsLabels, enablePtsDirection } = getConfig();

  const columns: TableColumn[] = [
    {
      title: "Operation",
      render: (v: PtsSchedule) => ptsLabels[DEFAULT_LANG_CODE]?.[v.id] || v.id,
    },
    getIntervalColumn("start"),
    getIntervalColumn("end"),
    {
      title: "Continuous",
      width: 1,
      getCellClass: (v: PtsSchedule) =>
        classNames("bold", { green: v.uninterruptible }),
      render: (v: PtsSchedule) => (v.uninterruptible ? "Yes" : "No"),
    },
  ];

  if (enablePtsDirection) {
    columns.splice(1, 0, {
      title: "Direction",
      render: (v: PtsSchedule) => v.direction,
    });
  }

  return columns;
};

const PtsDetailsPage = () => {
  const history = useHistory();
  const { ptsId } = useParams<{ ptsId?: string }>();
  const { ptsStore } = useContext(HomeContext);
  const {
    initPtsList,
    ready,
    setSelectedPtsId,
    highlightItemId,
    setHighlightItem,
    onRemoveOperations,
    getPtsById,
  } = ptsStore;
  const columns = useMemo(getColumns, []);

  const [selectedNotificationsIds, setSelectedNotificationsIds] = useState<
    string[]
  >([]);

  const [selectedPtsSchedule, setSelectedPtsSchedule] =
    useState<PtsSchedule | null>(null);

  const pts = getPtsById(ptsId);

  useEffect(() => {
    initPtsList();

    return () => setSelectedPtsId(null);
  }, []);

  useEffect(() => {
    if (ready && !pts) {
      history.push("/pts");
    }
  }, [pts]);

  if (!ready) return <Spinner />;

  if (!pts) {
    return null;
  }

  const newPtsSchedule = PtsScheduleStore.createNewPtsSchedule(pts);
  const { inboundFlightStatus, outboundFlightStatus } = pts.filters;
  const flightStatusTitle =
    !inboundFlightStatus && !outboundFlightStatus
      ? "-"
      : getStatusValue({
          inboundFlightStatus,
          outboundFlightStatus,
        });

  return (
    <Content>
      <section className={styles.ptsPage}>
        <div className={styles.header}>
          <a onClick={() => history.push("/pts/")} className={styles.backBtn}>
            <i className={"fas fa-arrow-left"} />
          </a>
          <h1>Details</h1>
          <ExportButton onClick={() => exportPtsScheduleToCSV(pts.schedules)} />
        </div>
        <div
          className={styles.details}
          onClick={() => setSelectedPtsId(pts.id)}
        >
          <div className={styles.info}>
            <span>Airline</span>
            <span>{pts.airline}</span>
            <span>Length </span>
            <span>
              {parseTurnaroundLengths(pts.filters.requiredTurnaroundLength) ||
                "-"}
            </span>
            <span>Active </span>
            <span>{pts.active ? "Yes" : "No"}</span>
            <span>Flight status</span>
            <span>{flightStatusTitle ? flightStatusTitle : "-"}</span>
          </div>
          <div className={styles.info}>
            <span>Description</span>
            <span>{pts.description || "-"}</span>
            <span>Included aircrafts</span>
            <span>
              {pts.filters.requiredAircraftTypes.length
                ? pts.filters.requiredAircraftTypes.join(", ")
                : "-"}
            </span>
            <span>Excluded aircrafts</span>
            <span>
              {pts.filters.excludedAircraftTypes.length
                ? pts.filters.excludedAircraftTypes.join(", ")
                : "-"}
            </span>
          </div>
        </div>

        <GroupHeader
          singularLabel="operation"
          pluralLabel="operations"
          group={"Operations"}
          activeItems={[]}
          selectedItems={selectedNotificationsIds}
          onAddItem={() => setSelectedPtsSchedule(newPtsSchedule)}
          onDelete={(ids) => onRemoveOperations(pts.id, ids)}
        />

        <Table
          columns={columns}
          getRowKey={(v: PtsSchedule) => v.id}
          items={pts.schedules}
          selectedItems={selectedNotificationsIds}
          onSelect={(ids) => setSelectedNotificationsIds(ids)}
          onRowClick={(v: PtsSchedule) => setSelectedPtsSchedule(v)}
          getRowClass={() => ""}
          highlightItemId={highlightItemId}
          onHighlightEnd={() => setHighlightItem(null)}
        />

        {selectedPtsSchedule && (
          <PtsOperationModal
            ptsStore={ptsStore}
            pts={pts}
            schedule={selectedPtsSchedule}
            onClose={() => setSelectedPtsSchedule(null)}
          />
        )}
      </section>
    </Content>
  );
};

export default observer(PtsDetailsPage);

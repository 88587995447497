import { observer } from "mobx-react";
import { ptsDirection, PtsSchedule } from "@models/pts";
import classNames from "classnames";
import modalStyles from "@components/PtsOperationModal/style.module.scss";
import { ReactComponent as ArrowIcon } from "@assets/arrow.svg";
import { ReactComponent as CirclePlusIcon } from "@assets/circle-plus.svg";
import PtsPreview from "../PtsPreview";
import { groupBy, sortBy, uniq } from "lodash";
import { getReferencePointTitle, getPtsLabel } from "@services/ptsUtils";
import {
  ModalCell,
  ModalSeparator,
  Switch,
  PrimaryButton,
  ModalLabel,
  PillCaption,
  ModalDropdownSelectOption,
  ModalHeader,
  Modal,
  ModalDropdownScreen,
} from "@frontend/assaia-ui";

import { ReactComponent as NavRightSVG } from "@assets/nav-right.svg";
import { useContext, useState } from "react";
import { PtsScheduleContext } from "@services/react";
import ModalDropdownButton from "../../Common/ModalDropdownButton";
import { getConfig } from "../../../di/di";
import { ModalSpacer } from "../../Common/ModalSpacer";

import styles from "./style.module.scss";
import { DEFAULT_LANG_CODE } from "@services/constants";

type Props = {
  onClose: () => void;
};

const SubcategoriesOptions: React.FC<{
  disabledSubcategories: string[];
  selectedSubcategoryId: string;
  onSubcategoryChange: (id: string) => void;
}> = observer(
  ({ disabledSubcategories, selectedSubcategoryId, onSubcategoryChange }) => {
    const { ptsOperationsGroups, operationLabels } = getConfig();
    const ptsGroupings = Object.entries(ptsOperationsGroups).map(
      ([ptsScheduleId, v]) => ({
        ptsScheduleId,
        ...v,
      })
    );

    const groupedPtsCategories = sortBy(
      Object.entries(groupBy(ptsGroupings, (v) => v.ptsCategoryId)),
      (v) => getPtsLabel(v[0])
    );

    return (
      <>
        {groupedPtsCategories.map(([categoryId, subcategories]) => {
          return (
            <>
              <ModalSeparator
                key={categoryId}
                title={getPtsLabel(categoryId)}
              />
              {sortBy(subcategories, (v) => getPtsLabel(v.ptsScheduleId)).map(
                ({ ptsScheduleId: id, operationList }) => {
                  const uniqOpLabels = uniq(
                    operationList.map(
                      (opName) =>
                        operationLabels[DEFAULT_LANG_CODE]?.[opName] || opName
                    )
                  ).map((opLabel) => (
                    <PillCaption key={opLabel}>{opLabel}</PillCaption>
                  ));

                  return (
                    <ModalCell
                      key={id}
                      className={classNames(styles.internalItem, {
                        [styles.disabled]: disabledSubcategories.includes(id),
                        [styles.selected]: id === selectedSubcategoryId,
                      })}
                      title={getPtsLabel(id)}
                      subtitle={
                        <div className={styles.caption}>{uniqOpLabels}</div>
                      }
                      onClick={() => onSubcategoryChange(id)}
                    />
                  );
                }
              )}
            </>
          );
        })}
      </>
    );
  }
);

const directions: ModalDropdownSelectOption<
  NonNullable<PtsSchedule["direction"]>
>[] = ptsDirection.map((id) => ({
  id,
  title: id,
}));

const PtsOperationMainScreen: React.FC<Props> = ({ onClose }) => {
  const { enablePtsDirection } = getConfig();
  const {
    pts,
    ptsScheduleData,
    onOperationChange,
    setScreen,
    onSubmitOperation,
    onSubcategoryChange,
  } = useContext(PtsScheduleContext);

  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id: subcategoryId, direction, uninterruptible } = ptsScheduleData;

  const disabledSubcategories =
    pts.schedules.filter((v) => v.id !== ptsScheduleData.id).map((v) => v.id) ||
    [];

  const submitHandler = async () => {
    setLoading(true);
    try {
      await onSubmitOperation();
      onClose();
    } catch (e: any) {
      alert(e.toString());
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ModalHeader
        icon={<CirclePlusIcon />}
        onClose={onClose}
        title="Add operation"
      />

      <PtsPreview
        startData={ptsScheduleData.start}
        endData={ptsScheduleData.end}
      />
      <ModalLabel title="Operation type">
        <ModalDropdownButton
          value={getPtsLabel(subcategoryId)}
          onClick={() => setOpened(true)}
        />
        {opened && (
          <Modal className={modalStyles.ptsOperationModal}>
            <ModalHeader
              title="Operation type"
              onBack={() => setOpened(false)}
              showBorderBottom={false}
            />
            <SubcategoriesOptions
              selectedSubcategoryId={subcategoryId}
              disabledSubcategories={disabledSubcategories}
              onSubcategoryChange={(id) => {
                onSubcategoryChange(id);
                setOpened(false);
              }}
            />
          </Modal>
        )}
      </ModalLabel>

      {enablePtsDirection && (
        <ModalLabel title="Direction">
          <ModalDropdownScreen
            emptyOption={true}
            title="Direction"
            options={directions}
            onSelect={(v) => onSubcategoryChange(v[0])}
            selectedIds={direction ? [direction] : []}
            value={direction || ""}
          />
        </ModalLabel>
      )}

      <ModalCell
        title={"Continuous"}
        onClick={() => onOperationChange({ uninterruptible: !uninterruptible })}
      >
        <Switch active={uninterruptible} />
      </ModalCell>
      <ModalSeparator title="Configuration" />
      <ModalCell
        title="Operation start"
        subtitle={`${getReferencePointTitle(
          ptsScheduleData.start.referencePoint
        )}: ${ptsScheduleData.start.idealTime}m`}
        leftIcon={<ArrowIcon />}
        rightIcon={<NavRightSVG />}
        onClick={() => setScreen("start")}
      />

      <ModalCell
        title="Operation end"
        subtitle={`${getReferencePointTitle(
          ptsScheduleData.end.referencePoint
        )}: ${ptsScheduleData.end.idealTime}m`}
        leftIcon={<ArrowIcon style={{ transform: "scaleX(-1)" }} />}
        rightIcon={<NavRightSVG />}
        onClick={() => setScreen("end")}
      />

      <ModalSpacer />

      <PrimaryButton disabled={loading} onClick={submitHandler}>
        Save
      </PrimaryButton>
    </>
  );
};

export default observer(PtsOperationMainScreen);
